import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { updateSearch } from 'bernie-core';
import { ErrorBoundary } from 'bernie-view';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { useStores } from 'src/common/stores/use-stores';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { RefTarget } from '@shared-ui/ref-context';
import { IdentifierType, RandomAccessOne } from '@shared-ui/retail-product-random-access-one';
import { ReviewsOverview, IdentifierType as ReviewsOverviewIdentifierType } from '@shared-ui/retail-product-reviews-overview';
import { adaptSearchCriteria } from 'stores/adapters/bex-api/common/search-criteria-adapter';
import { SimpleLazyLoadWrapper } from 'components/common/lazy/simple-lazy-load-wrapper';
import { SharedUiPropertyReviewsDialog } from './shared-ui-property-reviews-dialog';
export const sharedUIReviewsDialog = 'sui-see-all-reviews-dialog';
export const SharedUiPropertyReviewsWrapper = () => {
  var _a;
  const {
    context,
    currentHotel
  } = useStores();
  const history = useHistory();
  const location = useLocation();
  const propertyId = (_a = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId) === null || _a === void 0 ? void 0 : _a.toString();
  const searchCriteria = context && currentHotel && adaptSearchCriteria(context, currentHotel.searchCriteria);
  const onClickSeeMoreAndAllReviewsButton = reviewId => {
    const newParams = {
      pwaDialog: sharedUIReviewsDialog
    };
    if (reviewId) {
      newParams.reviewId = reviewId;
    }
    updateSearch({
      history,
      location,
      newParams: Object.assign({}, newParams)
    });
  };
  if (!propertyId) {
    return null;
  }
  return React.createElement(ErrorBoundary, {
    label: "hotel.information.reviews"
  }, React.createElement(EGDSSpacing, {
    margin: {
      block: 'six'
    }
  }, React.createElement(RefTarget, {
    name: "Reviews"
  }, React.createElement(Experiment, {
    name: "Reviews_Overview_full_feature_roll_out"
  }, React.createElement(ExperimentControl, null, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(RandomAccessOne, {
    inputs: {
      featureId: 'vision_reviews',
      productIdentifier: {
        id: propertyId,
        type: IdentifierType.PROPERTY_ID,
        travelSearchCriteria: {
          property: searchCriteria
        }
      }
    },
    batchKey: "default-product-details-experience-batch-key"
  }))), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(ReviewsOverview, {
    inputs: {
      productIdentifier: {
        id: propertyId,
        type: ReviewsOverviewIdentifierType.PROPERTY_ID,
        travelSearchCriteria: {
          property: searchCriteria
        }
      }
    },
    onClickSeeMoreAndAllReviewsButton: onClickSeeMoreAndAllReviewsButton
  })))), React.createElement(SharedUiPropertyReviewsDialog, {
    dialogType: sharedUIReviewsDialog
  }))));
};
SharedUiPropertyReviewsWrapper.displayName = 'SharedUiPropertyReviewsWrapper';