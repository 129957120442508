/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import { useSearchParams, useLocation } from 'react-router-dom-v5-compat';
import { inject, observer } from 'mobx-react';
import { parse } from 'query-string';
import { adaptSearchCriteria } from 'src/common/stores/adapters/bex-api/common/search-criteria-adapter';
import { isPetsAmenityFilterPresent } from 'src/common/utils/pets-utils';
import { useLocalization } from '@shared-ui/localization-context';
import { ContactHost } from '@shared-ui/retail-lodging-contact-host';
import { IdentifierType } from '@shared-ui/retail-product-answering-traveller-questions';
import { getPropertyOffersInput } from 'stores/adapters/bex-api/property-info/property-info-input-adapter';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const ContactHostWrapper = inject('context', 'currentHotel', 'analyticsSearch')(observer(({
  context,
  currentHotel,
  analyticsSearch,
  inputs,
  prominence
}) => {
  if (!currentHotel) {
    return null;
  }
  const l10n = useLocalization();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    hotelId,
    searchCriteria
  } = currentHotel;
  const urlParameters = parse(location.search);
  if (!context || !(currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria) || !currentHotel.hotelId) {
    return null;
  }
  const contactHostUpdateSearch = inquiryFormFieldData => {
    var _a, _b;
    const {
      dateChange,
      rooms,
      petsIncluded,
      dateRange
    } = inquiryFormFieldData;
    if (rooms) {
      const adults = (_a = rooms.reduce((count, room) => count + (room.adults || 0), 0)) !== null && _a !== void 0 ? _a : 0;
      const children = (_b = rooms.flatMap((room, roomIndex) => {
        var _a, _b;
        return (_b = (_a = room.children) === null || _a === void 0 ? void 0 : _a.map(({
          age
        }) => `${roomIndex + 1}_${age}`)) !== null && _b !== void 0 ? _b : [];
      })) !== null && _b !== void 0 ? _b : [];
      searchParams.set('adults', String(adults));
      searchParams.set('children', String(children));
      if (petsIncluded) {
        searchParams.set('amenities', 'PETS');
        searchParams.set('house_rules_group', 'pets_allowed');
      } else {
        searchParams.delete('amenities');
        searchParams.delete('house_rules_group');
      }
    }
    if (dateChange) {
      if ((dateRange === null || dateRange === void 0 ? void 0 : dateRange.selectedStartDate) && (dateRange === null || dateRange === void 0 ? void 0 : dateRange.selectedEndDate)) {
        const dateFormatter = dateInput => l10n.formatDate(new Date(dateInput.year, dateInput.month - 1, dateInput.day), {
          raw: 'yyyy-MM-dd'
        });
        const formattedNewStartDate = dateFormatter(dateRange === null || dateRange === void 0 ? void 0 : dateRange.selectedStartDate);
        const formattedNewEndDate = dateFormatter(dateRange === null || dateRange === void 0 ? void 0 : dateRange.selectedEndDate);
        searchParams.set('startDate', formattedNewStartDate);
        searchParams.set('endDate', formattedNewEndDate);
        searchParams.set('chkin', formattedNewStartDate);
        searchParams.set('chkout', formattedNewEndDate);
        searchParams.delete('dateless');
      } else {
        searchParams.delete('startDate');
        searchParams.delete('endDate');
        searchParams.delete('chkin');
        searchParams.delete('chkout');
        searchParams.set('dateless', 'true');
      }
    }
    if (searchParams.has('pwaDialog')) {
      // if this callback is triggered by submitting from a dialog, we have to wait out a race condition with bernie who also
      // updates the url parameters to remove the pwaDialog parameter. If we don't wait, the changes we wish to impart on the url
      // here will be lost
      setTimeout(() => setSearchParams(searchParams), 50);
    } else {
      setSearchParams(searchParams);
    }
  };
  const offersInputs = getPropertyOffersInput(context, currentHotel, urlParameters, undefined, analyticsSearch);
  const contactHostInput = {
    primaryPropertyCriteriaInput: {
      rooms: inputs.searchCriteria.primary.rooms,
      destination: {
        propertyIds: [inputs.propertyId]
      },
      dateRange: inputs.searchCriteria.primary.dateRange
    },
    petsIncluded: isPetsAmenityFilterPresent(context, currentHotel)
  };
  const contactHostInputWithProminence = Object.assign({
    prominence,
    inquiryFormInput: contactHostInput
  }, offersInputs);
  const ref = React.useRef(null);
  const travellerQnAInputs = React.useMemo(() => ({
    productIdentifier: {
      id: (hotelId === null || hotelId === void 0 ? void 0 : hotelId.toString()) || '',
      type: IdentifierType.PROPERTY_ID,
      travelSearchCriteria: {
        property: context && adaptSearchCriteria(context, searchCriteria, undefined, undefined, analyticsSearch)
      }
    }
  }), [hotelId, searchCriteria, context]);
  return React.createElement(ContactHost, {
    inputs: contactHostInputWithProminence,
    answeringTravellerQuestionInputs: travellerQnAInputs,
    onFieldsChangeCallback: contactHostUpdateSearch,
    triggerRef: ref,
    skipSsr: true
  });
}));
export const SharedUiContactHostWrapper = withSharedUiPropertyOffers(ContactHostWrapper);
SharedUiContactHostWrapper.displayName = 'SharedUiContactHostWrapper';