import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { PriceInsight } from '@shared-ui/lodging-property-offers';
import { RefTarget } from '@shared-ui/ref-context';
import { SimpleLazyLoadWrapper } from '../../../common/lazy/simple-lazy-load-wrapper';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const PriceInsightWrapper = ({
  inputs
}) => React.createElement(EGDSSpacing, {
  margin: {
    block: 'three'
  }
}, React.createElement(RefTarget, {
  name: "PriceInsight"
}, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(PriceInsight, {
  inputs: inputs,
  skipSsr: true
}))));
export const SharedUiPriceInsightWrapper = withSharedUiPropertyOffers(PriceInsightWrapper);
SharedUiPriceInsightWrapper.displayName = 'SharedUiPriceInsightWrapper';