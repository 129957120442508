import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { updateSearch } from 'bernie-core';
import { EGDSLayoutFlex } from '@egds/react-core/layout-flex';
import { EGDSLayoutPosition } from '@egds/react-core/layout-position';
import { DeviceType } from 'src/common/__generated__/api';
import { PRODUCT_DETAILS_EXPERIENCE_BATCH_KEY_BATCH_ONE } from 'src/common/constants/batchKeys';
import { useStores } from 'src/common/stores/use-stores';
import { Experiment, ExperimentControl, ExperimentVariant, useExperiment } from '@shared-ui/experiment-context';
import { IdentifierType as ProductGalleryIdentifierType, ProductGallery, ProductGalleryContext } from '@shared-ui/retail-product-gallery';
import { useSignalSubscriber } from '@shared-ui/signal-events-context';
import { ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { SharedUiSaveTripItemWrapper } from 'components/infosite/shared-ui/details/save-trip-item-wrapper';
import { SharedUiPropertySearchLinkWrapper } from 'components/infosite/shared-ui/offers/property-search-link-wrapper';
import { useQueryParamStoredPdpImages } from '../../property-details/use-stored-pdp-image-and-property-name';
import { SharedUiPropertyReviewsDialog } from '../reviews/shared-ui-property-reviews-dialog';
import { SharedUiShareActionsWrapper } from './share-actions-wrapper';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { SharedUiSocialShareButtonWrapper } from './social-share-button-wrapper';
const buttonsOnGallery = (deviceType, domain) => React.createElement(React.Fragment, null, React.createElement(EGDSLayoutPosition, {
  type: "absolute",
  position: {
    zindex: 'layer2',
    left: 'three',
    top: 'four'
  }
}, React.createElement(SharedUiPropertySearchLinkWrapper, null)), React.createElement(EGDSLayoutPosition, {
  type: "absolute",
  position: {
    zindex: 'layer2',
    right: deviceType === DeviceType.MOBILE_PHONE ? 'one' : 'three',
    top: deviceType === DeviceType.MOBILE_PHONE ? 'two' : 'four'
  }
}, React.createElement(EGDSLayoutFlex, {
  alignItems: "center",
  direction: "row",
  space: "two"
}, deviceType === DeviceType.DESKTOP ? React.createElement(Experiment, {
  name: "V2_VRBO_Desktop_Branch_Sharing"
}, React.createElement(ExperimentControl, null, React.createElement("div", {
  "data-stid": "share-button-without-label"
}, domain && React.createElement(SharedUiShareActionsWrapper, {
  domain: domain
}))), React.createElement(ExperimentVariant, {
  bucket: 1
}, React.createElement("div", {
  "data-stid": "share-button-with-label"
}, React.createElement(SharedUiSocialShareButtonWrapper, null)))) : React.createElement(Experiment, {
  name: "MWEB_BEX_HCOM_Branch_Sharing"
}, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
  bucket: 1
}, React.createElement(SharedUiSocialShareButtonWrapper, null))), React.createElement(SharedUiSaveTripItemWrapper, null))));
const buttonsOnGalleryLuxury = (deviceType, domain) => React.createElement("div", {
  "data-stid": "buttons-luxury-gallery"
}, React.createElement(EGDSLayoutPosition, {
  type: "absolute",
  position: {
    zindex: 'layer1',
    left: 'three',
    top: 'four'
  }
}, React.createElement(SharedUiPropertySearchLinkWrapper, null)), React.createElement(EGDSLayoutPosition, {
  type: "absolute",
  position: {
    zindex: 'layer1',
    right: 'three',
    top: 'four'
  }
}, React.createElement(EGDSLayoutFlex, {
  alignItems: "center",
  direction: "row",
  space: "two"
}, deviceType === DeviceType.DESKTOP ? React.createElement(Experiment, {
  name: "V2_VRBO_Desktop_Branch_Sharing"
}, React.createElement(ExperimentControl, null, domain && React.createElement(SharedUiShareActionsWrapper, {
  domain: domain
})), React.createElement(ExperimentVariant, {
  bucket: 1
}, React.createElement(SharedUiSocialShareButtonWrapper, null))) : React.createElement(Experiment, {
  name: "MWEB_BEX_HCOM_Branch_Sharing"
}, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
  bucket: 1
}, React.createElement(SharedUiSocialShareButtonWrapper, null))), React.createElement(SharedUiSaveTripItemWrapper, null))));
export const GalleryWrapper = ({
  inputs
}) => {
  const imagesFromUrl = useQueryParamStoredPdpImages();
  const {
    context: {
      deviceInformation,
      site: {
        domain
      }
    }
  } = useStores();
  const deviceType = deviceInformation === null || deviceInformation === void 0 ? void 0 : deviceInformation.type;
  const memoizedImages = React.useMemo(() => ({
    images: imagesFromUrl
  }), [imagesFromUrl]);
  const productGalleryInputs = React.useMemo(() => ({
    productIdentifier: {
      id: inputs.propertyId,
      type: ProductGalleryIdentifierType.PROPERTY_ID,
      travelSearchCriteria: {
        property: inputs.searchCriteria
      },
      shoppingContext: inputs.shoppingContext
    }
  }), [inputs.propertyId, inputs.searchCriteria, inputs.shoppingContext]);
  const {
    exposure: mWebLargerImageExposure
  } = useExperiment('mWeb_larger_image_for_top_of_PDP_gallery');
  const {
    exposure: adaptexCampaignMosaicLargeScreensExposure
  } = useExperiment('Adaptex_campaign_PDP_mosaic_for_large_screens');
  const {
    exposure: adaptexCampaignMosaicSmallScreenExposure
  } = useExperiment('Adaptex_campaign_PDP_mosaic_for_small_screens');
  const triggerReviewRef = React.useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [isLuxury, setLuxury] = React.useState(false);
  const {
    useControlGalleryAdaptexCampaign
  } = React.useContext(ProductGalleryContext);
  useSignalSubscriber({
    query: {
      type: 'LUXURY_PROPERTY'
    },
    onSignal: ({
      payload
    }) => setLuxury(() => payload.message)
  });
  const onClickSeeAllReviewsButton = () => {
    setTimeout(() => updateSearch({
      history,
      location,
      newParams: {
        pwaDialog: 'reviewFromGallery'
      }
    }), 100);
  };
  const shouldShowButtonsOnGallery = mWebLargerImageExposure.bucket !== 0 || adaptexCampaignMosaicSmallScreenExposure.bucket !== 0;
  return React.createElement(React.Fragment, null, React.createElement(EGDSLayoutPosition, {
    type: "relative"
  }, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(React.Fragment, null, shouldShowButtonsOnGallery && buttonsOnGallery(deviceType, domain))), React.createElement(ViewMedium, null, React.createElement(React.Fragment, null, isLuxury ? buttonsOnGalleryLuxury(deviceType, domain) : adaptexCampaignMosaicLargeScreensExposure.bucket !== 0 && !useControlGalleryAdaptexCampaign && buttonsOnGallery(deviceType, domain)))), React.createElement(Experiment, {
    name: "PDEA_Batched_Query_Split"
  }, React.createElement(ExperimentControl, null, React.createElement(ProductGallery, {
    inputs: productGalleryInputs,
    placeholderData: memoizedImages.images,
    onClickSeeAllReviewsButton: onClickSeeAllReviewsButton
  })), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(ProductGallery, {
    inputs: productGalleryInputs,
    placeholderData: memoizedImages.images,
    batchKey: PRODUCT_DETAILS_EXPERIENCE_BATCH_KEY_BATCH_ONE,
    onClickSeeAllReviewsButton: onClickSeeAllReviewsButton
  })))), React.createElement(SharedUiPropertyReviewsDialog, {
    dialogType: "reviewFromGallery",
    triggerRef: triggerReviewRef
  }));
};
GalleryWrapper.displayName = 'GalleryWrapper';
export const SharedUiGalleryWrapper = withSharedUiPropertyDetails(GalleryWrapper);
SharedUiGalleryWrapper.displayName = 'SharedUiGalleryWrapper';