/* eslint-disable max-lines */
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ClientLogger } from 'bernie-client';
import { updateSearch } from 'bernie-core';
import { EGDSCard, EGDSCardContentSection } from '@egds/react-core/cards';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { adjustSearchCriteriaWithSearchId } from 'src/common/utils/search-criteria-with-search-id';
import { EssClientLogger } from 'typeahead-client-logger';
import { Experiment, ExperimentControl, ExperimentVariant, useExperiment } from '@shared-ui/experiment-context';
import { ProductHighlights, PropertyInventorySource, PropertySpaceOverview, PropertySummaryHeader } from '@shared-ui/lodging-property-details';
import { Amenities, IdentifierType as ProductAmenitiesIdentifierType } from '@shared-ui/retail-product-amenities';
import { IdentifierType as ProductHeadlineIdentifierType, ProductHeadline } from '@shared-ui/retail-product-headline';
import { IdentifierType as RandomAccessOneIdentifierType, RandomAccessOne } from '@shared-ui/retail-product-random-access-one';
import { IdentifierType as ProductRatingSummaryIdentifier, ProductRatingSummary } from '@shared-ui/retail-product-rating-summary';
import { IdentifierType as ProductRelevantContentIdentifier, RelevantContent } from '@shared-ui/retail-product-relevant-content';
import { useStores } from 'stores/use-stores';
import { MapDialogWrapper } from 'components/infosite/shared-ui/details/map-dialog-wrapper';
import { SharedUiMap } from 'components/infosite/shared-ui/details/map-wrapper';
import { withSharedUiPropertyDetails } from 'components/infosite/shared-ui/details/shared-ui-property-details';
import { SharedUiPropertyReviewsDialog } from 'components/infosite/shared-ui/reviews/shared-ui-property-reviews-dialog';
import { CheckInOutWrapper } from '../shared-ui/details/check-in-out-wrapper';
import { usePropertyDetailsQueryCSRExperiment } from '../shared-ui/details/use-property-details-query';
import { LocationSectionWrapper } from './location-section-wrapper';
const SummaryWrapper = inject('analyticsSearch')(observer(({
  analyticsSearch,
  inputs
}) => {
  var _a;
  const {
    map
  } = useStores();
  const history = useHistory();
  const location = useLocation();
  const triggerMapRef = React.useRef(null);
  const triggerReviewRef = React.useRef(null);
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  const {
    bucket: isModularised
  } = useExperiment('PDP_MODULARISED_LOCATION_SECTION').exposure;
  const isAppShellPropertyVariant = useExperiment('App_Shell_Property_Requirement_Web', true).exposure.bucket === 1;
  const onClickMap = React.useCallback(() => updateSearch({
    history,
    location,
    newParams: {
      pwaDialog: 'map'
    }
  }), [history, location]);
  const onClickReviews = React.useCallback(() => updateSearch({
    history,
    location,
    newParams: {
      pwaDialog: 'reviews'
    }
  }), [history, location]);
  const onClose = React.useCallback(() => history.goBack(), [history]);
  const inputsWithSearchId = adjustSearchCriteriaWithSearchId(inputs.searchCriteria, analyticsSearch === null || analyticsSearch === void 0 ? void 0 : analyticsSearch.searchId);
  const setSavedFeature = map ? customPlace => map.setCustomPlace(customPlace) : undefined;
  const savedFeature = (_a = map === null || map === void 0 ? void 0 : map.customPlaces) === null || _a === void 0 ? void 0 : _a[0];
  const essLogger = new EssClientLogger(analyticsSearch, ClientLogger, '');
  const mapProps = {
    inputs,
    onClose,
    setSavedFeature,
    savedFeature,
    essLogger,
    triggerRef: triggerMapRef
  };
  const relevantContentVariants = skipSsrRendering => React.createElement(EGDSLayoutFlexItem, null, React.createElement(EGDSSpacing, {
    padding: {
      blockend: 'six'
    }
  }, React.createElement("div", null, React.createElement(RelevantContent, {
    inputs: {
      productIdentifier: {
        id: inputs.propertyId,
        type: ProductRelevantContentIdentifier.PROPERTY_ID,
        travelSearchCriteria: {
          property: inputs.searchCriteria
        }
      }
    },
    skipSsr: skipSsrRendering
  }))));
  const locationComponent = React.createElement(LocationSectionWrapper, {
    inputs: inputs,
    onClickMap: onClickMap,
    skipSsr: cSRPropertyDetailsQuery
  }, React.createElement(SharedUiMap, Object.assign({}, mapProps)));
  const RelevantContentWrapper = () => React.createElement(Experiment, {
    name: "Filter_ranking_model_for_amenity_module_Web"
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  }, relevantContentVariants(false)), React.createElement(ExperimentVariant, {
    bucket: 2
  }, relevantContentVariants(true)));
  return React.createElement(EGDSSpacing, {
    margin: {
      blockend: isModularised ? undefined : 'six',
      blockstart: 'half'
    }
  }, React.createElement(EGDSCard, {
    className: "custom-css-flat-border-top"
  }, React.createElement(EGDSCardContentSection, {
    padded: !isAppShellPropertyVariant
  }, React.createElement(EGDSSpacing, {
    padding: {
      medium: {
        inline: 'three',
        blockstart: 'three',
        blockend: isModularised ? undefined : 'three'
      },
      large: {
        inline: isAppShellPropertyVariant ? 'unset' : 'three'
      }
    }
  }, React.createElement(EGDSLayoutFlex, {
    direction: "column"
  }, React.createElement(React.Fragment, null, React.createElement(EGDSLayoutFlexItem, null, React.createElement(EGDSSpacing, {
    padding: {
      blockend: 'four'
    }
  }, React.createElement("div", {
    "data-stid": "content-hotel-title"
  }, React.createElement(Experiment, {
    name: "PDEA_Headline_Module_Web"
  }, React.createElement(ExperimentControl, null, React.createElement(PropertySummaryHeader, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery
  })), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(ProductHeadline, {
    inputs: {
      productIdentifier: {
        id: inputs.propertyId,
        type: ProductHeadlineIdentifierType.PROPERTY_ID,
        travelSearchCriteria: {
          property: inputs.searchCriteria
        }
      }
    }
  })))))), React.createElement(EGDSLayoutFlexItem, null, React.createElement(ProductRatingSummary, {
    inputs: {
      productIdentifier: {
        id: inputs.propertyId,
        type: ProductRatingSummaryIdentifier.PROPERTY_ID,
        travelSearchCriteria: {
          property: inputs.searchCriteria
        }
      }
    },
    onClickReviews: onClickReviews,
    triggerRef: triggerReviewRef
  })), React.createElement(EGDSLayoutFlexItem, null, React.createElement(RandomAccessOne, {
    inputs: {
      featureId: 'guest_choice_tpl',
      productIdentifier: {
        id: inputs.propertyId,
        type: RandomAccessOneIdentifierType.PROPERTY_ID,
        travelSearchCriteria: {
          property: inputsWithSearchId
        }
      }
    }
  })), React.createElement(ProductHighlights, {
    inputs: {
      productIdentifier: {
        id: inputs.propertyId,
        type: ProductRelevantContentIdentifier.PROPERTY_ID,
        travelSearchCriteria: {
          property: inputsWithSearchId
        }
      }
    },
    pageId: "HOT.HIS.Dated",
    onClickReviews: onClickReviews
  }), React.createElement(EGDSLayoutFlexItem, null, React.createElement(PropertySpaceOverview, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery
  })), React.createElement(EGDSLayoutFlexItem, null, React.createElement(PropertyInventorySource, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery
  })), React.createElement(EGDSLayoutFlexItem, null, React.createElement(EGDSSpacing, null, React.createElement("div", null, React.createElement(Amenities, {
    inputs: {
      productIdentifier: {
        id: inputs.propertyId,
        type: ProductAmenitiesIdentifierType.PROPERTY_ID,
        travelSearchCriteria: {
          property: inputs.searchCriteria
        }
      }
    }
  })))), React.createElement(RelevantContentWrapper, null), React.createElement("div", null, React.createElement(CheckInOutWrapper, null)), locationComponent)))), React.createElement(MapDialogWrapper, null), React.createElement(SharedUiPropertyReviewsDialog, {
    dialogType: "reviews",
    triggerRef: triggerReviewRef
  })));
}));
const MemoizedSummaryWrapper = React.memo(({
  inputs
}) => React.createElement(SummaryWrapper, {
  inputs: inputs
}));
export const SingleOfferSummaryWrapper = withSharedUiPropertyDetails(MemoizedSummaryWrapper);
SingleOfferSummaryWrapper.displayName = 'SingleOfferSummaryWrapper';