import * as React from 'react';
import { EGDSCard, EGDSCardContentSection } from '@egds/react-core/cards';
import { EGDSLayoutFlex } from '@egds/react-core/layout-flex';
import { EGDSLayoutPosition } from '@egds/react-core/layout-position';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { InquiryProminence } from 'src/common/__generated__/api';
import { useRefProvider } from 'src/common/stores/contexts/ref-provider';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { SharedUiPropertyNumberWrapper } from '../shared-ui/details/property-number-wrapper';
import { SharedUiContactHostWrapper } from '../shared-ui/offers/contact-host-wrapper';
import { SharedUiPropertySingleOfferWrapper } from '../shared-ui/offers/single-offer-price-wrapper';
export const SingleOfferPriceWrapper = () => {
  const [singleOfferPrice] = useRefProvider('singleOfferPrice');
  const priceBlock = withBorder => React.createElement(React.Fragment, null, React.createElement(EGDSSpacing, {
    margin: {
      large: {
        block: 'three',
        inlinestart: 'three'
      }
    }
  }, React.createElement(EGDSCard, {
    overflow: true,
    border: withBorder
  }, React.createElement(EGDSSpacing, {
    padding: {
      blockstart: 'three',
      blockend: 'six',
      large: {
        inline: 'three'
      }
    }
  }, React.createElement(EGDSCardContentSection, {
    padded: false
  }, React.createElement(SharedUiPropertySingleOfferWrapper, null))))), React.createElement(EGDSSpacing, {
    margin: {
      blockstart: 'six',
      inlinestart: 'unset'
    }
  }, React.createElement(EGDSLayoutFlex, {
    justifyContent: "center"
  }, React.createElement(SharedUiContactHostWrapper, {
    prominence: InquiryProminence.LOW
  }))), React.createElement(SharedUiPropertyNumberWrapper, null));
  return React.createElement(Viewport, null, React.createElement(ViewLarge, null, React.createElement(EGDSLayoutPosition, {
    type: "sticky",
    position: {
      top: 'twelve',
      zindex: 'layer3'
    },
    cloneElement: true
  }, React.createElement("div", {
    ref: singleOfferPrice
  }, priceBlock(true)))), React.createElement(ViewSmall, null, React.createElement("div", {
    ref: singleOfferPrice
  }, priceBlock(false))));
};