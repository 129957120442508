import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { useExperiment } from '@shared-ui/experiment-context';
import { RecommendationsModuleWrapper } from '../similar-hotels/recommendations-module-wrapper';
export const SUVRCarousel = () => {
  const isSmaShPropertyRecsNewCarouselVariant = useExperiment('SmaSh_Property_Recs_New_Carousel').exposure.bucket === 1;
  return isSmaShPropertyRecsNewCarouselVariant ? React.createElement("div", {
    "data-stid": "suvr-carousel"
  }, React.createElement(EGDSSpacing, {
    border: ['blockstart', 'blockend'],
    padding: {
      block: 'six',
      small: {
        block: 'three'
      }
    }
  }, React.createElement(RecommendationsModuleWrapper, null))) : null;
};