import * as React from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { inject } from 'mobx-react';
import { ErrorBoundary } from 'bernie-view';
import { Level, liveAnnounce } from '@egds/react-core/utils';
import { getDateRangeInput } from 'src/common/stores/adapters';
import { useLocalization } from '@shared-ui/localization-context';
import { LodgingPropertySingleOffer, PropertyOffersFormSubmitType } from '@shared-ui/lodging-property-offers';
import { RefTarget } from '@shared-ui/ref-context';
import { parseDate } from '../form/field-wrappers/single-unit-date-field-wrapper';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
export const offersFormSubmit = (l10n, setSearchParams, searchParams, currentHotel) => (offersFormData, offersSubmitType) => {
  const {
    isChecked,
    adults,
    children,
    petsIncluded,
    startDate,
    endDate
  } = offersFormData;
  switch (offersSubmitType) {
    case PropertyOffersFormSubmitType.TRAVELER_SELECTOR:
      currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.setOfferData({
        adults,
        children,
        petsIncluded
      });
      return;
    case PropertyOffersFormSubmitType.LODGING_ONE_KEY_BURN_SWITCH:
      searchParams.set('useRewards', isChecked ? 'true' : 'false');
      setSearchParams(searchParams);
      setTimeout(() => liveAnnounce(l10n === null || l10n === void 0 ? void 0 : l10n.formatText(isChecked ? 'common.pointsApplied' : 'common.pointsRemoved'), Level.POLITE), 500);
      return;
    case PropertyOffersFormSubmitType.AVAILABILITY_CALENDAR:
      {
        if (startDate && endDate) {
          const dateFormatter = dateInput => l10n.formatDate(new Date(dateInput.year, dateInput.month - 1, dateInput.day), {
            raw: 'yyyy-MM-dd'
          });
          const formattedNewStartDate = dateFormatter(parseDate(startDate));
          const formattedNewEndDate = dateFormatter(parseDate(endDate));
          searchParams.set('startDate', formattedNewStartDate);
          searchParams.set('endDate', formattedNewEndDate);
          searchParams.set('chkin', formattedNewStartDate);
          searchParams.set('chkout', formattedNewEndDate);
          searchParams.delete('dateless');
        } else {
          searchParams.delete('startDate');
          searchParams.delete('endDate');
          searchParams.delete('chkin');
          searchParams.delete('chkout');
          searchParams.set('dateless', 'true');
        }
        if (searchParams.has('pwaDialog')) {
          // if this callback is triggered by submitting from a dialog, we have to wait out a race condition with bernie who also
          // updates the url parameters to remove the pwaDialog parameter. If we don't wait, the changes we wish to impart on the url
          // here will be lost
          setTimeout(() => setSearchParams(searchParams), 50);
        } else {
          setSearchParams(searchParams);
        }
        return;
      }
    default:
      return;
  }
};
export const PropertySingleOfferWrapper = inject('context', 'experiment', 'currentHotel')(({
  context,
  currentHotel,
  inputs
}) => {
  var _a;
  if (!currentHotel) {
    return null;
  }
  const l10n = useLocalization();
  const searchCriteria = currentHotel.searchCriteria;
  const [searchParams, setSearchParams] = useSearchParams();
  const travelerSingleUnitData = {
    inputs: inputs,
    hotelId: String((_a = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId) !== null && _a !== void 0 ? _a : ''),
    localDateFormat: context === null || context === void 0 ? void 0 : context.localDateFormat,
    dateRangeInput: searchCriteria && getDateRangeInput(searchCriteria),
    offersFormSubmit: offersFormSubmit(l10n, setSearchParams, searchParams, currentHotel)
  };
  return React.createElement("div", null, React.createElement(RefTarget, {
    name: "Offers",
    tabIndex: 0
  }, React.createElement(ErrorBoundary, {
    label: "lodging.pdp.single.offer"
  }, React.createElement(LodgingPropertySingleOffer, {
    travelerSingleUnitData: travelerSingleUnitData,
    skipSsr: true
  }))));
});
PropertySingleOfferWrapper.displayName = 'PropertySingleOfferWrapper';
export const SharedUiPropertySingleOfferWrapper = withSharedUiPropertyOffers(PropertySingleOfferWrapper);