import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { ConfigurationIdentifiers } from 'src/common/constants/configuration-identifiers';
import { getShoppingContextInput } from 'src/common/stores/adapters';
import { adaptSearchCriteria } from 'src/common/stores/adapters/bex-api/common/search-criteria-adapter';
import CarouselContext from 'src/common/stores/contexts/carousel-context-provider';
import { usePropertyCarouselContexts } from 'src/common/utils/carousel-utils';
import { useExperiment } from '@shared-ui/experiment-context';
import { RecommendationModuleStatus } from '@shared-ui/retail-recommendation-module';
import { SponsoredPropertiesCarouselWrapper } from '../../meso/sponsored-properties-carousel-wrapper';
import { RecommendationsModuleWrapper } from '../similar-hotels/recommendations-module-wrapper';
export const PropertyPrimaryCarousel = inject('currentHotel', 'multiItem')(observer(props => {
  var _a, _b, _c, _d;
  const {
    context,
    searchCriteria,
    isProperties: isSponsoredCampaign,
    isLoading,
    availableRooms,
    recommendationModulesStatus
  } = usePropertyCarouselContexts();
  const multiItemContext = (_a = props.multiItem) === null || _a === void 0 ? void 0 : _a.multiItemContext;
  const shoppingContext = getShoppingContextInput(multiItemContext);
  const propertySearchCriteria = adaptSearchCriteria(context, searchCriteria);
  propertySearchCriteria.primary.destination.excludedPropertyIds = [`${(_c = (_b = props.currentHotel) === null || _b === void 0 ? void 0 : _b.hotelId) !== null && _c !== void 0 ? _c : null}`];
  const {
    status: recommendationSponsoredCarouselStatus
  } = (_d = recommendationModulesStatus.find(item => item.id === ConfigurationIdentifiers.LODGING_DATED_PDP_SPONSORED_PROPERTIES_CAROUSEL)) !== null && _d !== void 0 ? _d : {};
  const isSponsoredCarouselRendered = isSponsoredCampaign || recommendationSponsoredCarouselStatus === RecommendationModuleStatus.RENDERED;
  const {
    handleSponsoredCarouselRendered,
    handleRecommendationsCarouselRendered
  } = React.useContext(CarouselContext);
  const isSmaShPropertyRecsNewCarouselVariant = useExperiment('SmaSh_Property_Recs_New_Carousel').exposure.bucket === 1;
  React.useEffect(() => {
    if (isSponsoredCarouselRendered && availableRooms) {
      handleSponsoredCarouselRendered();
    }
    if (isSponsoredCarouselRendered || !availableRooms) {
      handleRecommendationsCarouselRendered();
    }
  }, [isSponsoredCarouselRendered, isLoading, availableRooms]);
  return React.createElement(React.Fragment, null, availableRooms && React.createElement(SponsoredPropertiesCarouselWrapper, {
    useLazyLoading: false,
    recommendationSponsoredCarouselStatus: recommendationSponsoredCarouselStatus
  }), !shoppingContext.multiItem && (!isSponsoredCarouselRendered || !availableRooms) && isSmaShPropertyRecsNewCarouselVariant && React.createElement("div", {
    "data-stid": "property-recs"
  }, React.createElement(EGDSSpacing, {
    border: ['blockstart', 'blockend'],
    padding: {
      block: 'six',
      small: {
        block: 'three'
      }
    },
    margin: {
      small: {
        inline: 'three'
      },
      medium: {
        inline: 'unset'
      }
    }
  }, React.createElement(RecommendationsModuleWrapper, null))));
}));